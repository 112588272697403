<template>
  <div id="school-class-info"></div>
</template>

<script>
import { fetchUser } from '@/assets/js/app_info'

export default {
  data() {
    return {
      pageTitle: 'School Class Info',
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    fetchUser().then((user) => {
      if (user.role == 'host' || user.role == 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'School Classes',
            route: `/school/${this.schoolId}/school_classes`,
          },
          {
            name: 'Results',
            route: `/school/${this.schoolId}/school_class/${this.$route.params.id}/results`,
          },
          {
            name: 'Promote',
            route: `/school/${this.schoolId}/school_class/${this.$route.params.id}/promote`,
          },
          {
            name: 'Master List',
            route: `/school/${this.schoolId}/school_class/${this.$route.params.id}/master_list`,
          },
          {
            name: 'Bulk Result Email',
            route: `/school/${this.schoolId}/school_class/${this.$route.params.id}/bulk_result_email`,
          },
        ])
      } else if (user.role == 'educator') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Assigned Classes',
            route: `/school/${this.schoolId}/educator_assigned_classes`,
          },
        ])
      }
    })
  },
}
</script>
